// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-markdown-js": () => import("/home/ubuntu/asphalt/src/templates/markdown.js" /* webpackChunkName: "component---src-templates-markdown-js" */),
  "component---src-pages-404-js": () => import("/home/ubuntu/asphalt/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activity-js": () => import("/home/ubuntu/asphalt/src/pages/activity.js" /* webpackChunkName: "component---src-pages-activity-js" */),
  "component---src-pages-admin-activity-js": () => import("/home/ubuntu/asphalt/src/pages/admin/activity.js" /* webpackChunkName: "component---src-pages-admin-activity-js" */),
  "component---src-pages-admin-announcement-js": () => import("/home/ubuntu/asphalt/src/pages/admin/announcement.js" /* webpackChunkName: "component---src-pages-admin-announcement-js" */),
  "component---src-pages-admin-associator-js": () => import("/home/ubuntu/asphalt/src/pages/admin/associator.js" /* webpackChunkName: "component---src-pages-admin-associator-js" */),
  "component---src-pages-admin-course-js": () => import("/home/ubuntu/asphalt/src/pages/admin/course.js" /* webpackChunkName: "component---src-pages-admin-course-js" */),
  "component---src-pages-admin-download-js": () => import("/home/ubuntu/asphalt/src/pages/admin/download.js" /* webpackChunkName: "component---src-pages-admin-download-js" */),
  "component---src-pages-admin-index-js": () => import("/home/ubuntu/asphalt/src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-login-js": () => import("/home/ubuntu/asphalt/src/pages/admin/login.js" /* webpackChunkName: "component---src-pages-admin-login-js" */),
  "component---src-pages-admin-markdown-js": () => import("/home/ubuntu/asphalt/src/pages/admin/markdown.js" /* webpackChunkName: "component---src-pages-admin-markdown-js" */),
  "component---src-pages-admin-news-js": () => import("/home/ubuntu/asphalt/src/pages/admin/news.js" /* webpackChunkName: "component---src-pages-admin-news-js" */),
  "component---src-pages-admin-user-js": () => import("/home/ubuntu/asphalt/src/pages/admin/user.js" /* webpackChunkName: "component---src-pages-admin-user-js" */),
  "component---src-pages-associator-list-js": () => import("/home/ubuntu/asphalt/src/pages/associator/list.js" /* webpackChunkName: "component---src-pages-associator-list-js" */),
  "component---src-pages-associator-select-js": () => import("/home/ubuntu/asphalt/src/pages/associator/select.js" /* webpackChunkName: "component---src-pages-associator-select-js" */),
  "component---src-pages-contact-js": () => import("/home/ubuntu/asphalt/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/home/ubuntu/asphalt/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-property-course-js": () => import("/home/ubuntu/asphalt/src/pages/property/course.js" /* webpackChunkName: "component---src-pages-property-course-js" */),
  "component---src-pages-property-download-js": () => import("/home/ubuntu/asphalt/src/pages/property/download.js" /* webpackChunkName: "component---src-pages-property-download-js" */)
}

